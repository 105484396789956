.chat {
  text-align: center;
  color: #fff;
  position: absolute;
  top: 94px;
  right: 0;
  bottom: 0;
  overflow:hidden;
  left: 0;
  font-family:"Söhne",-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  font-size:1.1rem;
}

.newChat{
  position: absolute;
  font-size: 12px;
  top: -20px;
}

header {
  display: none;
}

header h1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #d9d9e3;
  margin: auto;
}

#chatPrompt p {
  margin: 0;
  padding: 0;
  text-indent: 0;
  font-size:1em;
  white-space: pre-wrap;
  position: relative;
  font-family:"Söhne",-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji !important;
}

#botMessage p {
  margin: 0;
  padding: 0;
  text-indent: 0;
  font-size:1em;
  white-space: pre-wrap;
  position: relative;
}

@keyframes fadeInChar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.chatBox {
  flex: 1;
  background-color: #353540;
  position: relative;
  line-height: 24px;
  color: #d1d5db;
  font-size: 15px;
}
#introsection::before,
#introsection::after {
  float: left;
  color: rgb(0, 134, 244);
}
#introsection {
  text-align: left;
  padding: 20px;
}

#introsection {
  color: #BBB;
  padding-top:1rem;
  padding-left:10rem;
  padding-right:10rem;
}

#introsection h1 {
  line-height: 33px;
}
#introsection h2 {
  font-size: 16px;
  font-weight: 400;
}
#introsection ul {
  list-style-type: square;
}
#introsection pre {
  margin: 0;
  display: inline;
}
.chatLogWrapper {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top:6rem;
  padding-bottom:13rem;
}

/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

::-moz-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

.chatPromptWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}

.userSVG {
  transform: scale(0.6);
}
.botMessageMainContainer {
  width: 100%;
  background-color: #25384d;
  background-color: #223245;
}

.botMessageWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  color:#d1d5db;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}

.errorMessage {
  color: #ef4444;
  font-size: 16px;
  line-height: 24px;
}

.openaiSVG {
  transform: scale(0.5);
}
#avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  object-fit: contain;
  object-position: center;
}

.inputPromptWrapper {
  left: 0;
  right: 0;
  top: 2rem;
  position: relative;
  padding-top: 10px;
}
.inputPrompttTextarea {
  width: 80%;
  max-height: 200px;
  height: 48px;
  padding: 10px;
  resize: none;
  font-size: 16px;
  background-color: #41414e;
  color: #f8f6f6;
  outline: none;
  border: 2px solid #333;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  overflow-y: hidden;
}

@media screen and (min-width: 768px) {
  .inputPromptWrapper {
    position: absolute;
  }
}

@media screen and (max-width: 1024px) {
  .App {
    display: block;
    /* overflow: hidden; */
    
  }
  header {
    display: flex;
    align-items: center;
    background: #353441;
    border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
    padding: 4px 0 0 12px;
    /* overflow: hidden; */

  }
  nav {
    display: flex;
    /* overflow: hidden; */

  }
  .sideMenu {
    display: none;
    /* overflow: hidden; */

  }
  .chatBox {
    position: static;
    /* overflow: hidden; */

    
  }
  .chatPromptWrapper {
    padding: 12px;
    overflow: hidden;
  }
  .botMessageWrapper {
    padding: 12px;
    overflow: hidden;
  }
  .userSVG {
    transform: scale(0.5);
  }
  .openaiSVG {
    transform: scale(0.4);
  }
  #avatar {
    width: 30px;
    height: 30px;
  }
  #introsection {
    padding: 20px 20px 40px 20px;
    font-size: 14px;
  }
  #introsection h1,
  pre {
    font-size: 14px;
    white-space: pre-wrap;
  }
  #introsection h2 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
}
@media only screen and (max-width: 767px) {
    .send-button {
      display: inline;
      position: absolute;
      top: 31px ;
      right: -92px;
    }
}

@media only screen and (max-width: 767px) {
  .chatLogWrapper {
    height: 72vh;
    overflow-y: auto;
  }
}


