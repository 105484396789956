@font-face{
  font-family:"Signifier";
  font-style:normal;
  src:url(./fonts/Signifier-Regular.otf)
}

@font-face{
  font-family:"Söhne Bold";
  font-style:normal;
  src:url(./fonts/Sohne-Halbfett.otf)
}

@font-face{
  font-family:"Söhne";
  font-style:normal;
  -webkit-text-size-adjust:100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  src:url(./fonts/Sohne-Buch.otf)
}

@font-face{
  font-family:"Söhne Mono";
  font-style:normal;
  src:url(./fonts/SohneMono-Buch.otf)
}

html,
body {
  padding: 0;
  margin: 0;
  margin: 0;
  font-family:"Söhne","ui-sans-serif","system-ui","-apple-system","Segoe UI","Roboto","Ubuntu","Cantarell","Noto Sans","sans-serif","Helvetica Neue","Arial","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

body {
  overflow: hidden;
}

#root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

code {
  font-family: "Söhne Mono",source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace !important;
}

a {
  text-decoration:none;
}
